import React, { useState } from "react";
import { AddCircleOutline } from "@mui/icons-material";
import {
  Typography,
  Box,
  Button,
  Modal,
  TextField,
  LinearProgress,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import axios from "axios";
import { toast } from "react-toastify";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  getResFromLocalStorage,
  getUserFromLocalStorage,
} from "src/service/localStorage";
import { useEffect } from "react";
import SelfPromotionTable from "src/components/SelfPromotions/SelfPromotionTable";

function SelfPromotion() {
  const [productData, setProductData] = useState([]);
  const [open, setOpen] = useState(false);
  const [product, setProduct] = useState({
    title: "",
    description: "",
    productLink: "",
  });
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const API_URL = process.env.REACT_APP_PROD_API;
  const {
    data: { access_token },
  } = getUserFromLocalStorage();
  const {
    data: { affiliate_id },
  } = getResFromLocalStorage();

  const isValidURL = (string) => {
    try {
      new URL(string);
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setProduct({ title: "", description: "", productLink: "" });
    setImage(null);
  };

  const handleChange = (field, value) => {
    setProduct((prev) => ({ ...prev, [field]: value }));
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!product.title || !product.description || !product.productLink) {
      toast.error("Please fill in all fields.");
      return;
    }
    if (!isValidURL(product.productLink)) {
      toast.error("Please enter a valid product link.");
      return;
    }
    if (!image) {
      toast.error("Please select an image.");
      return;
    }

    const formData = new FormData();
    formData.append("file", image);
    const queryParams = new URLSearchParams({
      title: product.title,
      description: product.description,
      product_link: product.productLink,
    }).toString();

    setLoading(true);
    try {
      const response = await axios.post(
        `${API_URL}/api/adspace/self_promotion?${queryParams}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      toast.success("Product uploaded successfully!");
      handleClose();
    } catch (error) {
      console.error("Error uploading product:", error);
      toast.error("Failed to upload product.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/api/adspace/self_promotion?affiliate_id=${affiliate_id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${access_token}`,
            },
          }
        );
        setProductData(response.data);
      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    };
    fetchData();
  }, [API_URL, access_token, affiliate_id]);

  console.log(productData, "productData");

  const theme = useTheme();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    width: { xs: "90%", md: "40%" },
    borderRadius: 2,
  };

  return (
    <div>
      <Box
        sx={{
          background: "linear-gradient(180deg, #A5BFD9 9.55%, #A7D3FF 100%)",
          [theme.breakpoints.up("md")]: { p: 2, minHeight: "100vh" },
          [theme.breakpoints.up("xs")]: { p: 3, minHeight: "100vh" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography
            sx={{
              color: "#12314F",
              fontWeight: "bold",
              fontSize: { xs: "20px", md: "30px" },
            }}
          >
            Self Promotion
          </Typography>
          <Button
            variant="contained"
            startIcon={<AddCircleOutline />}
            onClick={handleOpen}
          >
            Add
          </Button>
        </Box>

        <section>
          <SelfPromotionTable productData={productData} />
        </section>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Create Promotion
            </Typography>
            <form onSubmit={handleSubmit} style={{ marginTop: "1rem" }}>
              <TextField
                label="Title"
                fullWidth
                margin="normal"
                value={product.title}
                onChange={(e) => handleChange("title", e.target.value)}
              />
              <TextField
                label="Description"
                fullWidth
                margin="normal"
                multiline
                rows={3}
                value={product.description}
                onChange={(e) => handleChange("description", e.target.value)}
              />
              <TextField
                label="Product Link"
                fullWidth
                margin="normal"
                value={product.productLink}
                onChange={(e) => handleChange("productLink", e.target.value)}
              />

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  mt: 2,
                }}
              >
                <Button
                  variant="outlined"
                  component="label"
                  startIcon={<CloudUploadIcon />}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      display: { xs: "none", md: "block" },
                    }}
                  >
                    Upload Image
                  </Typography>
                  <input
                    type="file"
                    accept="image/*"
                    hidden
                    onChange={handleImageChange}
                  />
                </Button>
                {image && (
                  <Typography variant="body2" color="textSecondary">
                    {image.name}
                  </Typography>
                )}
              </Box>

              {loading && <LinearProgress sx={{ mt: 2 }} />}

              <Button
                type="submit"
                variant="contained"
                sx={{ mt: 3 }}
                fullWidth
                disabled={loading}
              >
                {loading ? "Submitting..." : "Submit"}
              </Button>
            </form>
          </Box>
        </Modal>
      </Box>
    </div>
  );
}

export default SelfPromotion;
