import React from "react";
import {
  Table, TableBody, TableCell,
  TableContainer, TableHead,
  TableRow, Paper, Typography,
  Button, Link, Box
} from "@mui/material";

const SelfPromotionTable = ({ productData }) => {
  return (
    <Box sx={{ overflowX: "auto", width: "100%", padding: 2 }}>
      <TableContainer
        component={Paper}
        sx={{
          margin: "auto", width: "100%",
          borderRadius: "8px",
          height: "80vh",
        }}
      >
        {productData.length > 0 ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" sx={{ minWidth: 80, fontWeight: "bold" }}>
                  Image
                </TableCell>
                <TableCell align="center" sx={{ minWidth: 180, fontWeight: "bold" }}>
                  Title
                </TableCell>
                <TableCell align="center" sx={{ minWidth: 250, width: 300, fontWeight: "bold" }}>
                  Description
                </TableCell>
                <TableCell align="center" sx={{ minWidth: 120, fontWeight: "bold" }}>
                  Link
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {productData.map((product) => (
                <TableRow key={product.product_id}>
                  <TableCell align="center">
                    <img
                      src={product.image_url}
                      alt={product.title}
                      style={{
                        width: "100px",
                        height: "auto",
                        borderRadius: "8px",
                        objectFit: "cover",
                        margin: "auto",
                      }}
                    />
                  </TableCell>
                  <TableCell align="center">
                    {product.title}
                  </TableCell>
                  <TableCell align="center">
                    {product.description}
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      variant="contained"
                      color="primary"
                      component={Link}
                      href={product.product_link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Visit
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Typography variant="h6" align="center" sx={{ padding: 4 }}>
            No Products Available.
          </Typography>
        )}
      </TableContainer>
    </Box>
  );
};

export default SelfPromotionTable;
